<template>
  <div class="quick-toolbar">
    <div class="wrapper">
      <p class="title">什么是客户画像？</p>
      <p class="desc">
        客户画像记录了客户的基本信息、客户来源、客户阶段、客户标签以及客户动态等信息。员工与客户会话时能够快速了解客户情况，判断客户意向，提高工作效率。
      </p>
    </div>

    <div class="wrapper">
      <div class="mb16">
        <span class="title">如何使用？</span>
        <router-link class="ml8" :to="{name: 'system_setting_chat_toolbar'}">配置教程</router-link>
      </div>

      <p class="desc">
        配置成功后，员工登录企业微信，在与客户或者客户群的聊天对话框右侧点击侧边栏的图标，即可看到客户画像工具栏。
      </p>
    </div>

    <div class="wrapper">
      <a-image class="display-img"
        src="https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/persondialogue/image/f1bf2f1956a3acace97edf0bd6d3b1e1"
        alt="客户画像图" />
    </div>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";

export default {
  name: "CustomerPortrait",
  components: {
    AImage: Image,
  },
};
</script>